.ant-layout-header {
    //background: @primary-color;
    background: #e60000; 
    color: white;
    padding: 0 30px;
  }
  
  .ant-layout-content {
    min-height: 100vh;
  }
  
  .content {
    max-width: 1000px;
    margin: auto;
  }
  
  .header-link {
    color: white;
  }
  
  .header-link:hover {
    color: #EEEEEE;
  }
@primary-color: #0057A4;@text-color: #424242;@font-size-base: 20px;@height-base: 40px;@steps-icon-font-size: @font-size-base;
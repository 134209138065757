form{
    width:80%;
    display:flex;
    flex-flow: row wrap;
    //justify-content: space-between;
    
    
}
h2{
    width:100%;
    padding-top:40px;
    @media only screen and (max-width: 560px) {
        text-align: center;
    }
}
.confirmReturnFormItem, .confirmNonReturnFormItem{
    width:133px;
    margin-right:80px;
    button{
        width:133px;
        background-color:#e60000;
        color:#ffffff;
        font-weight: 500;
    }
    button:hover{
            background-color: #bd0100;
            color:#ffffff;
    }
    @media only screen and (max-width: 560px) {
        width:100%;
        margin-right:0px;
        button{
            width:100%;
        }
    }

}
@media only screen and (max-width: 560px) {
    .content{
        padding: 20px;
        form{
            width:100%;
        }
    }
}


//vodafone red : #e60000

@primary-color: #0057A4;@text-color: #424242;@font-size-base: 20px;@height-base: 40px;@steps-icon-font-size: @font-size-base;